@function size($font-size) {
  @return ($font-size / 1920px) * 100vw;
}
.ts-filter {
  font-size: 14px;
  &--in-container {
    margin-top: 7px;
  }
  .ts-section-header & {
    &--tabs {
      margin-top: 11px;
    }
  }
  &--fright {
    float: right;
  }
  &__label {
    text-transform: uppercase;
    vertical-align: middle;
    font-size: 16px;
    .ts-filter__container--pos-full & {
      width: 100%;
      display: block;
    }
    &--left {
      margin-right: 5px;
    }
    &--not-first {
      margin-left: 15px;
    }
  }
  &__list {
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    &--category {
      text-align: center;
    }
    &--no-margin-left {
      margin-left: 0;
    }
  }
  &__item {
    list-style: none;
    float: left;
    &--category {
      float: none;
      display: inline-block;
      margin: 0 5px 8px;
      width: 164px;
      text-align: center;
    }
  }
  &__item-select {
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    background: #fff;
    font-size: 12px;
    height: 40px;
    padding: 0 15px;
    text-align: left;
    width: 180px;
    .ts-filter__container--pos-full & {
      width: 100%;
      display: block;
    }
    &--medium {
      width: 160px;
    }
    &[disabled] {
      background-color: #F2F0F0;
    }
  }
  &__container {
    &--pos-full {
      padding-right: 0;
      float: none;
    }
    &--fleft {
      float: left;
      padding-right: 20px;
      &:last-child {
        padding-right: 0;
      }
    }
    &--fright {
      float: right;
      text-align: right;
      padding-left: 20px;
      position: relative;
      &:last-child {
        padding-left: 0;
      }
    }
    &--tabs {
      float: right;
      padding-right: 30px;
    }
    &--choose-color {
      padding-bottom: 5px;
    }
    &--search {
      padding-right: 20px;
    }
  }
  &__title {
    color: #000000;
    font-size: 18px;
    text-transform: uppercase;
    &--fleft {
      float: left;
    }
    &--fright {
      float: right;
    }
    &--sort-panel {
      line-height: 40px;
      padding-left: 14px;
    }
  }
  &__link {
    display: block;
    color: #000000;
    border-bottom: 1px dashed #000;
    text-decoration: none;
    &:hover {
      border-bottom: transparent;
      text-decoration: none;
      color: #32C1E9;
    }
    &--fright {
      float: right;
    }
    &--fleft {
      float: left;
    }
  }

  &__switch-label {
    display: block;
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
    background-color: #F6F6F6;
    border: 1px solid #D9D9D9;
    color: #545454;
    text-transform: uppercase;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: #32C1E9;
      border: 1px solid #32C1E9;
      color: #fff;
    }
    &--rounded {
      border-radius: 3px;
    }
  }
  &__radiobutton {
    position: absolute;
    opacity: 0;
    &:checked + .ts-filter__switch-label {
      background-color: #32C1E9;
      border: 1px solid #32C1E9;
      color: #fff;
    }
  }
  &__checkbox {
    position: absolute;
    opacity: 0;
    &:checked + .ts-filter__switch-label {
      background-color: #32C1E9;
      border: 1px solid #32C1E9;
      color: #fff;
    }
  }
  &__input-set {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid #979797;
    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
    }
  }
  &__menu-header {
    padding-bottom: 3px;
  }
  &__menu-header-name {
    float: left;
    text-transform: uppercase;
  }
  &__menu-tags {
    padding-top: 3px;
  }
}