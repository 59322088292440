@function size($font-size) {
  @return ($font-size / 1920px) * 100vw;
}
.density {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 326px;
  margin: 0 auto;
  &.is-total {
    justify-content: center;
  }
}