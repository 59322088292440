@import '../../../assets/styles/variables.scss';
.geo-filter {
  padding: 20px 0 0;
  color: #070707;
  &--front-page {
    top: 90px;
  }
  &--chain-page {
    top: 50px;
  }
  &.is-open {
    display: block;
  }
  &__toogle-button {
    text-decoration: underline;
    cursor: pointer;
    z-index: 221;
    position: relative;
    &:hover {
      text-decoration: none;
    }
  }
  &__header {
    border-bottom: 1px solid #E0E0E0;
    padding: 0 0 10px 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  &__title {
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 700;
    flex: 1 0 0;
    text-align: left;
  }
  &__tabs {
    flex: 1 0 0;
    text-align: left;
  }
  &__search {
    margin-right: 20px;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    font-size: 14px;
    height: 32px;
    width: 180px;
    padding: 0 10px;
    @media screen and (min-width: $break-medium) {
      width: 200px;
    }
  }
  &__total-reset {
    width: 120px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    background-color: #00325b;
    border: 1px solid #00325b;
    border-radius: 26px;
    font-size: 12px;
    color: #fff;
    text-decoration: none;
    @media screen and (min-width: $break-medium) {
      width: 150px;
      font-size: 14px;
    }
    &:hover {
      background-color: lighten(#00325b, 10%);
      text-decoration: none;
    }
  }
  &__item {
    height: 30px;
    text-align: center;
    cursor: pointer;
    background-color: #F6F6F6;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    color: #070707;
    text-decoration: none;
    @media screen and (min-width: $break-medium) {
      line-height: 29px;
    }
    &:hover {
      background-color: #00325b;
      border-color: #00325b;
      color: #fff;
      text-decoration: none;
    }
    &.is-checked {
      background-color: #00325b;
      border-color: #00325b;
      color: #fff;
    }
  }
  &__item-title {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 12px;
    @media screen and (min-width: $break-medium) {
      font-size: 14px;
    }
  }
  &__item-outer {
    flex-grow: 0;
    flex-basis: 12.5%;
    padding: 0 .6%;
    .is-ie & {
      flex-basis: 12%;
      padding: 0 .25%;
      &--3-col {
        flex-basis: 32.8%;
      }
    }
    &--3-col {
      flex-basis: 33.3%;
    }
  }
  &__area-wrapp {
    margin: 8px -20px 0 0;
    &.is-ie > div > div {
      max-height: calc(100vh - 277px + 15px);
      //margin-right: -15px;
    }
  }
  &__area-set {
    display: flex;
    flex-wrap: wrap;
    &:last-child {
      padding-bottom: 10px;
    }
  }
  &__area-cols-wrapp {
    overflow: hidden;
    padding-right: 20px;
  }
  &__area-col {
    flex-grow: 0;
    flex-basis: 12.5%;
    padding: 0 5px;
  }
}

.geo-tabs {
  display: flex;
  align-items: center;
  &__item {
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    margin-right: 20px;
    transition: all 0.5s ease;
    @media screen and (min-width: $break-medium) {
      font-size: 16px;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: lighten(#D2232A, 15);
      font-size: 18px;
      @media screen and (min-width: $break-medium) {
        font-size: 20px;
      }
    }
    &.is-active {
      color: #00325b;
      font-size: 18px;
      @media screen and (min-width: $break-medium) {
        font-size: 20px;
      }
    }
    &.is-no-link {
      cursor: default;
    }
  }
}