@function size($font-size) {
  @return ($font-size / 1920px) * 100vw;
}
.indexing {
  &__row {
    margin: 0 -3px;
    display: flex;
  }
  &__item {
    flex-grow: 1;
    color: #000;
    text-align: center;
    font-size: 16px;
    text-transform: lowercase;
  }
  &__item-inner {
    position: relative;
    margin: 0 3px;
    padding-top: 8px;
    border: 1px solid #979797;
    height: 50px;
    &__item:first-child .indexing__item-inner {
      margin-left: 0;
    }
  }
}