@import '../../../../../assets/styles/variables.scss';
.method-commute {
  &__col {
    width: 20%;
    float: left;
    text-align: center;
  }
  &__data {
    width: 66px;
    height: 66px;
    margin: 0 auto 7px;
    background-color: #32C1E9;
    border-radius: 50%;
    line-height: 68px;
    color: #fff;
    font-size: 20px;
    @media screen and (min-width: $break-small) {
      font-size: 24px;
    }
    @media screen and (min-width: $break-medium) {
      font-size: 28px;
      width: 86px;
      height: 86px;
      line-height: 88px;
    }
  }
  &__percent {
    font-size: 14px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-top: 29px;
    @media screen and (min-width: $break-medium) {
      font-size: 18px;
    }
  }
  &__title {
    font-size: 14px;
    height: 34px;
    position: relative;
    @media screen and (min-width: $break-medium) {
      font-size: 16px;
    }
  }
}