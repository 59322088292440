@function size($font-size) {
  @return ($font-size / 1920px) * 100vw;
}
.line-chart {
  display: flex;
  margin: 0 -7px;
  font-size: 14px;
  width: 100%;
  .t-right {
    text-align: right;
  }
  &__col--item {
    padding: 0 7px;
  }
  &__col {
    flex-grow: 0;
    &--full {
      flex-grow: 3;
    }
  }
  &__col-flex {
    display: flex;
  }
  &__row {
    line-height: 20px;
  }
  &__progress-block {
    border-radius: 5px;
    background-color: #EBEBEB;
    width: 100%;
    height: 10px;
    position: relative;
    display: inline-block;
  }
  &__progress {
    border-radius: 5px;
    background-color: #32C1E9;
    position: absolute;
    height: 10px;
    //width: 0;
    transition: all 2s ease;
  }
  &__label-name {
    font-size: 15px;
  }
}

@-webkit-keyframes progress-bar {
  0% { width: 0; }
}
@-moz-keyframes progress-bar {
  0% { width: 0; }
}
@keyframes progress-bar {
  0% { width: 0; }
}
.line-chart__progress {
  -webkit-animation: progress-bar 1.5s;
  -moz-animation: progress-bar 1.5s;
  animation: progress-bar 1.5s;
}

.line-chart-header {
  position: relative;
  margin-bottom: 7px;
  &--area {
    text-align: center;
    height: 15px;
  }
  &--bordered {
    &:before {
      content: '';
      position: absolute;
      width: calc(100% - 14px);
      left: 7px;
      top: 4px;
      height: 7px;
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: #404752;
    }
  }
  &__title {
    color: #8CA0B3;
    font-size: 11px;
    position: relative;
    background-color: #fff;
    text-transform: uppercase;
    &--area {
      color: #404752;
      padding: 0 5px;
      position: absolute;
    }
  }
}

