$pi:3.14;
@import 'variables';
//config
$menu-items:5+1;
$open-distance:60px;
//$opening-angle:$pi*2;
$opening-angle:$pi * 1.5;

$_precision: 10;

@function pow($base, $exp) {
  $value: $base;
  @if $exp > 1 {
    @for $i from 2 through $exp {
      $value: $value * $base;
    }
  }
  @if $exp < 1{
    @for $i from 0 through -$exp {
      $value: $value / $base;
    }
  }
  @return $value;
}

@function fact($num) {
  $fact: 1;
  @if $num > 0{
    @for $i from 1 through $num {
      $fact: $fact * $i;
    }
  }
  @return $fact;
}

@function _to_unitless_rad($angle) {
  @if unit($angle) == "deg" {
    $angle: $angle / 180deg * $pi;
  }
  @if unit($angle) == "rad" {
    $angle: $angle / 1rad;
  }
  @return $angle;
}

@function sin($angle){
  $a: _to_unitless_rad($angle);
  $sin: $a;
  @for $n from 1 through $_precision {
    $sin: $sin + (pow(-1, $n) / fact(2 * $n + 1) ) * pow($a, (2 * $n + 1));
  }
  @return $sin;
}

@function cos($angle){
  $a: _to_unitless_rad($angle);
  $cos: 1;
  @for $n from 1 through $_precision {
    $cos: $cos + ( pow(-1,$n) / fact(2*$n) ) * pow($a,2*$n);
  }
  @return $cos;
}

.gooey-menu {
  position: absolute;
  right: -46px;
  pointer-events: visible;
  @media screen and (min-width: $break-large) {
    right: -58px;
  }
  &__label,
  &__item {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 46px;
    border-radius: 20px;
    cursor: pointer;
    background-color: #333333;
    path, ellipse, rect, use, svg, g, circle {
      fill: #fff;
    }
  }
  &__label {
    z-index: 3;
    transition-timing-function:cubic-bezier(0.175, 0.885, 0.320, 1.275);
    transition-duration:400ms;
    transform:scale(1.1,1.1) translate3d(0,0,0);
    cursor:pointer;
    position: relative;
    text-align: center;
    &:hover {
      background-color: #00325b;
      transform: scale(1.4, 1.4) translate3d(0, 0, 0);
      & > .tip-body {
        display: block;
        transform: scale(0.7, 0.7) translate3d(0, 0, 0);
        //right: 20px;
      }
    }
    &.is-orange {
      background-color: #f26522;
      &:hover {
        background-color: darken(#f26522, .5);
      }
    }
  }
  &__item {
    position: absolute;
    top: 0;
    z-index: 2;
    color: #fff;
    white-space: nowrap;
    transform:translate3d(0,0,0);
    transition:transform ease-out 400ms, width .1s, opacity .1s;
    &:hover > .tip-body {
      display: block;
    }
  }
  &__item-icon {
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding-top: 8px;
    &.is-ppt,
    &.is-xls {
      font-size: 22px;
      padding-top: 6px;
      padding-left: 6px;
    }
  }
  &__item-title {
    font-size: 16px;
    font-weight: 300;
    display: inline-block;
    vertical-align: top;
    padding-top: 14px;
  }
  &.is-active {
    .gooey-menu__label {
      background-color: #00325b;
    }
    .gooey-menu__item {
      z-index: 3;
      &:hover {
        z-index: 4;
      }
      transition-timing-function: cubic-bezier(0.935, 0.000, 0.340, 1.330);
      @for $i from 1 through $menu-items {
        $angle: (($pi - $opening-angle)/2)+(($opening-angle/($menu-items - 1))*($i - 1));

        &:nth-child(#{$i+1}) {
          transition-duration: 80ms+(100ms*$i);
        }
      }
      &:nth-child(2) {
        transform: rotate(28deg) translate(-60px) rotate(-28deg);
        @media screen and (min-width: $break-large) {
          transform: rotate(-28deg) translate(60px) rotate(28deg);
        }
      }
      &:nth-child(3) {
        transform: rotate(-28deg) translate(-60px) rotate(28deg);
        @media screen and (min-width: $break-large) {
          transform: rotate(28deg) translate(60px) rotate(-28deg);
        }
      }
      &:nth-child(4) {
        transform: rotate(-85deg) translate(-56px) rotate(85deg);
        @media screen and (min-width: $break-large) {
          transform: rotate(85deg) translate(56px) rotate(-85deg);
        }
      }
    }
  }
}