@import '../../../assets/styles/variables.scss';
.summary-segments {
  &__col {
    text-align: center;
  }
  &__data {
    font-size: 44px;
    @media screen and (min-width: $break-large) {
      font-size: 50px;
    }
    &.is-green {
      color: #3FA648;
    }
    &.is-blue {
      color: #0095D6;
    }
  }
  &__title {
    font-size: 15px;
    text-transform: uppercase;
    margin-top: 2px;
    @media screen and (min-width: $break-large) {
      font-size: 18px;
    }
  }
  &__caption {
    font-size: 15px;
    height: 26px;
    border-radius: 30px;
    line-height: 30px;
    width: 88%;
    margin: 3px auto 0;
    color: rgba(0, 0, 0, 0.5);
    @media screen and (min-width: $break-large) {
      width: 60%;
    }
    &.is-green {
      background: rgba(64, 184, 89, 0.12);
    }
    &.is-blue {
      background: rgba(0, 149, 214, 0.11);
    }
  }
}