@import '../../../assets/styles/variables.scss';

.main-header {
  background-color: #00325b;
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  padding: 0 0 0 25px;
  z-index: 103;
  box-shadow: 0 1px 2px 1px rgba(0,0,0,0.10);
  &.is-gtm {
    background-color: #933F6B;
  }
  &__trick {
    position: absolute;
    top: 0;
    left: 0;
    width: 272px;
    height: 56px;
    z-index: 1;
    background-color: #00325b;
    &.is-gtm {
      background-color: #933F6B;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    z-index: 102;
  }
  &__el-small {
    flex-grow: 0;
  }
  &__el-full {
    flex-grow: 3;
  }
  &__el-tall {
    height: 100%;
  }
  &__el-title {
    flex-grow: 3;
    position: relative;
    z-index: 102;
    &.is-absolute {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    @media screen and (min-width: $break-medium) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
  &__logo-holder {
    display: inline-block;
    z-index: 2;
    position: relative;
    margin-top: 6px;
    text-decoration: none;
  }
  &__nav-logo {
    width: 166px;
    height: 44px;
    display: flex;
    align-items: center;

    &-img {
      object-fit: contain;
      width: 54px;
      margin-right: 8px;
    }

    &-text {
      font-size: 18px;
      border: 0;
      color: white;
    }
  }
  &__logo {
    max-width: 88px;
    height: auto;
    &:hover {
      opacity: .8;
    }
  }
  &__user-name {
    color: #fff;
    font-size: 14px;
    &.is-action {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__col-border {
    padding-right: 26px;
    margin-right: 26px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 66px;
      margin-left: -26px;
      right: 0;
      top: -10px;
      background-color: #fff;
    }
  }
  &__logout {
    display: inline-block;
    vertical-align: middle;
    margin: 3px 0 0 7px;
    font-size: 20px;
    path, ellipse, rect, use, svg, g, circle {
      fill: #fff;
    }
    &:hover {
      path, ellipse, rect, use, svg, g, circle {
        fill: #fff;
      }
    }
  }
  &__title {
    text-align: center;
    color: #fff;
    font-weight: 900;
    font-size: 24px;
    text-transform: uppercase;
    padding: 20px;
    line-height: 16px;
    @media screen and (min-width: $break-medium) {
      font-size: 28px;
    }
  }
}

.user {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, .4);
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  padding-top: 12px;
  transition: background-color .3s;
  &:hover {
    background-color: rgba(255, 255, 255, .3);
  }
  &__icon {
    font-size: 18px;
  }
  &__info {
    text-align: center;
    position: absolute;
    top: 54px;
    right: 24px;
    height: 69px;
    min-width: 212px;
    overflow: hidden;
    transform: translate(120%);
    transition: transform .3s;
    &.is-visible {
      transform: translate(0);
    }
  }
  &__info-inner {
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    padding: 8px 12px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
  }
  &__name {
    font-size: 12px;
  }
  &__buttons-set {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.is-center {
      justify-content: center;
    }
  }
  &__success {
    display: inline-block;
    height: 24px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: #0C6484;
    margin-top: 14px;
  }
  &__success-icon-holder {
    display: inline-block;
    background-color: #0C6484;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    margin-right: 4px;
    padding-top: 5px;
    vertical-align: middle;
    svg {
      width: 16px;
      height: 10px;
    }
  }
  &__success-text {
    vertical-align: middle;
    //padding-top: 1px;
  }
  &__button {
    display: inline-block;
    width: 67px;
    height: 24px;
    border: 1px solid #00325b;
    background-color: transparent;
    border-radius: 91px;
    color: #00325b;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    margin: 15px 10px 0;
    cursor: pointer;
    white-space: nowrap;
    .is-center & {
      margin: 15px 0 0;
    }
    &:hover {
      color: #fff;
      text-decoration: none;
      background-color: #00325b;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &.is-contact {
      width: 120px;
      border: 1px solid #00325b;
      background-color: #00325b;
      color: #fff;
      &:hover {
        background-color: transparent;
        color: #00325b;
      }
      &.is-gtm {
        border: 1px solid #933F6B;
        background-color: #933F6B;
        &:hover {
          background-color: transparent;
          color: #933F6B;
        }
      }
    }
  }
}

.user-holder {
  height: 56px;
  position: relative;
  padding: 8px 24px;
}