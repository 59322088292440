.g-table {
  width: 100%;
  font-size: 11px;
  @media screen and (min-width: $break-medium) {
    font-size: 13px;
  }
  &__th {
    text-transform: uppercase;
    padding: 26px 10px 22px;
    height: 74px;
    &:not(:first-child) {
      max-width: 126px;
    }
    &:last-child {
      .is-sticky & {
        border-radius: 0;
      }
    }
    &.is-nowrap {
      white-space: nowrap;
    }
  }
  &__th:not(:first-child),
  &__td:not(:first-child) {
    @media screen and (min-width: $break-large) {
      &.is-big {
        min-width: 126px;
      }
      &.is-medium {
        min-width: 100px;
      }
      &.is-small {
        min-width: 80px;
      }
      &.is-smaller {
        min-width: 74px;
      }
    }
  }
  &__td {
    padding: 14px 10px;
    text-transform: uppercase;
    border-top: 1px dashed #CBCFDF;
    &:first-child {
      padding-left: 16px;
    }
  }
  tr:first-child td {
    border-top: 0;
  }
  .is-parent + tr .g-table__td {
    border-top: 0;
  }
  .is-subheader {
    background-color: #333333;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-transform: none;
    border-top: 0;
    @media screen and (min-width: $break-medium) {
      font-size: 20px;
    }
  }
  .is-stars {
    text-align: center;
    white-space: nowrap;
    &:hover {
      background-color: lighten(#333333, 10%);
    }
  }
  &__dot-set {
    display: inline-block;
    height: 10px;
    .is-sticky & {
      height: 0;
      overflow: hidden;
    }
  }
  &__dot {
    display: inline-block;
    background-color: #933F6B;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 2px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.col-hidden {
  padding-top: 0;
  padding-bottom: 0;
  border: 0
}
.content-hidden {
  height: 0;
  line-height: 0;
  overflow: hidden
}

.table-w-sticky {
  transition: all 0.5s ease;
  &.is-sticky {
    margin-top: 61px;
  }
}

.table-header {
  transition: all 0.5s ease;
  &.is-sticky {
    position: fixed;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    width: 100%;
    top: 99px;
    left: 0;
    z-index: 10;
    transition: all 0.5s ease 0s;
  }
}