.error-content {
  text-align: center;
  display: flex;
  align-items: center;
  height: calc(100vh - 102px - 60px);

  background-repeat: no-repeat;
  background-position: center;
  background-size: 66% auto;
  &__inner {
    width: 100%;
  }
  &__title {
    font-size: 68px;
    color: lighten(#000000, 80%);
    padding-bottom: 30px;
  }
  &__error-name {
    font-size: 270px;
    color: #ffffff;
    line-height: 270px;
  }
  &__caption {
    font-size: 18px;
    padding: 60px 0 40px;
    color: #fff;
  }
  &__link {
    background-color: #22A8F3;
    color: #fff;
    display: inline-block;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    border-radius: 3px;
    padding: 15px 45px 10px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      background-color: darken(#22A8F3, 10%);
    }
  }
  &__text-helper {
    position: relative;
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-width: 25px;
      border-style: solid;
      border-color: #EE5050 transparent transparent transparent;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}