@function size($font-size) {
  @return ($font-size / 1920px) * 100vw;
}
.main-footer {
  background-color: #201F25;
  width: 100%;
  height: 32px;
  color: #fff;
  font-size: 12px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 103;
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    width: 100%;
    padding: 0 24px;
    margin: 0 auto;
  }
  &__el-full {
    flex-grow: 3;
  }
  &__el-small {
    flex-grow: 0;
  }
  &__copy {
    font-size: 12px;
    line-height: 16px;
  }
  &__logo-holder {
    a {
      display: block;
    }
  }
  &__logos-text {
    color: #7D8387;
    text-transform: lowercase;
  }
  &__ds-logo {
    max-width: 126px;
    height: auto;
    margin: 3px 25px 0;
  }
  &__kinetic-logo {
    width: 40px;
    height: 20px;
  }
}