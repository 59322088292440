.landing {
  position: relative;
  min-height: 100%;
  height: auto;
  //background-color: #000;
  //background-image: url("../images/bg-landing.jpg");
  //background-size: cover;
  //background-repeat: no-repeat;
  //background-position: center top;
  overflow: hidden;
  &__intro-block {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
  &__title {
    font-weight: 900;
    font-size: 56px;
    color: #fff;
    padding: 0 40px 80px;
    animation: intro-title 1s, intro-title-hide 1s ease-out forwards 1;
    animation-delay: 0s, 3s;
    animation-iteration-count: 1, 1;
  }
  &__title-caption {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    line-height: 26px;
    sup {
      font-size: 12px;
    }
    .is-animate & {
      animation: intro-title .5s ease-out forwards 1;
      animation-delay: 4.5s;
      animation-iteration-count: 1;
      opacity: 0;
    }
  }
  &__intro-bottom {
    animation: intro-bottom 1s, intro-bottom-hide 1s ease-out forwards 1;
    animation-delay: 0s, 3s;
  }
  &__intro-text {
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    padding-top: 80px;
  }

  &__logo-new {
    width: 1105px;
    height: 200px;
    left: 50%;
    top: 20px;
    transform: translate(-50%, 0);
    position: absolute;
    object-fit: contain;
    .is-animate & {
      width: 1105px;
      height: 0;
      max-height: 236px;
      animation: intro-logo 2s, intro-logo-change .5s ease-out forwards 1;
      animation-delay: 0s, 2s;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.landing-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  max-width: 1400px;
  margin: 0 auto;
  &__button {
    text-decoration: none;
    position: relative;
    .is-animate & {
      width: 0;
      height: 0;
      max-width: 320px;
      max-height: 320px;
    }
    &:hover {
      .landing-content__description {
        transform: translate(-50%, 0);
        height: 102px;
      }
    }
  }
  .is-animate & {
    .animation-button-1 {
      animation: intro-button .3s linear forwards 1;
      animation-delay: 2s;
      overflow: hidden;
    }
    .animation-button-2 {
      animation: intro-button .3s linear forwards 1;
      animation-delay: 2s;
      overflow: hidden;
    }
    .animation-button-3 {
      animation: intro-button .3s linear forwards 1;
      animation-delay: 2s;
      overflow: hidden;
    }
    .animation-inner-1 {
      animation: inner-button .3s ease-out forwards 1;
      animation-delay: 3s;
    }
    .animation-inner-2 {
      animation: inner-button .3s ease-out forwards 1;
      animation-delay: 3.5s;
    }
    .animation-inner-3 {
      animation: inner-button .3s ease-out forwards 1;
      animation-delay: 4s;
    }
  }
  &__button-inner {
    display: flex;
    width: 208px;
    height: 208px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    margin: 56px;
    .is-animate & {
      transform: scale(0);
    }
    &.is-landscape {
      background-image: radial-gradient(closest-side at 104px 46px, #244991 0%, #00325b 100%);
    }
    &.is-gtm {
      background-image: radial-gradient(closest-side at 104px 46px,
              lighten(#933F6B, 12%) 0%, #933F6B 100%);
    }
    &.is-food-journey {
      background-image: radial-gradient(closest-side at 104px 46px,
              lighten(#0C6484, 10%) 0%, #0C6484 100%);
    }
    &:before {
      transform: scale(1.1);
      content: "";
      display: block;
      position: absolute;
      width: 204px;
      height: 204px;
      top: 0;
      left: 0;
      border-radius: 50%;
      border: 2px dashed rgba(255, 255, 255, .6);
      transition: all 1s;
    }
    &:hover:before {
      transform: scale(1.2) rotate(45deg);
    }
  }
  &__button-title {
    font-weight: bold;
    font-size: 24px;
    color: #fff;
    line-height: 28px;
  }
  &__description {
    position: absolute;
    width: 400px;
    height: 0;
    overflow: hidden;
    left: 50%;
    transform: translate(-50%, -100%);
    transition: transform .3s, height .1s;
    animation: intro-description .1s linear forwards 1;
    animation-delay: 3s;
    opacity: 0;
  }
  &__description-inner {
    padding: 12px 20px;
    line-height: 24px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    border: 3px solid;
    .is-landscape & {
      border-color: rgba(255, 255, 255, 0.6);
    }
    .is-gtm & {
      border-color: #933F6B;
    }
    .is-food-journey & {
      border-color: #126887;
    }
  }
}

@keyframes inner-button {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes intro-description {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes intro-button {
  0% {
    width: 0;
    height: 320px;
    overflow: hidden;
  }
  100% {
    width: 320px;
    height: 320px;
    overflow: visible;
  }
}

.loader {
  width: 42px;
  height: 42px;
  border: 1px solid #D2232A;
  border-radius: 50%;
  margin: 20px auto 0;
  position: relative;
  padding-top: 8px;
  &__spinner {
    width: 24px;
    height: 24px;
    animation: rotate 2s linear infinite;
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes intro-title {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes intro-title-hide {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@keyframes intro-bottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes intro-bottom-hide {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}
@keyframes intro-logo {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 236px;
  }
}
@keyframes intro-logo-change {
  0% {
    height: 236px;
  }
  100% {
    top: 20px;
    transform: translate(-50%, 0);
    height: 80px;
  }
}

@keyframes logo {
  0% {
    transform: scale(0.2);
    //opacity: 0;
  }
  100% {
    transform: scale(1);
    //opacity: 1;
  }
}

.landing-logo {
  width: 187px;
  height: 40px;
  .is-animate & {
    //width: 0;
    //opacity: 0;
    transform: scale(0);
    animation: logo 1s linear forwards 1;
    animation-delay: 4s;
  }
}
.landing-logo-holder {
  position: absolute;
  top: 32px;
  left: 32px;
}