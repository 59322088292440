.ts-mod-header {
  text-transform: uppercase;
  color: #000;
  font-size: 16px;
  text-align: center;
  padding-bottom: 10px;
  @media screen and (min-width: $break-large) {
    font-size: 20px;
  }
  &--low-padding {
    padding-bottom: 6px;
  }
  &--left {
    text-align: left;
  }
  &--border {
    border-top: 1px solid #979797;
    margin-top: 36px;
    padding-bottom: 0;
  }
  &--glance {
    border-top: 1px solid #979797;
    margin-top: 12px;
    padding-bottom: 0;
  }
  &__title {
    position: relative;
    background-color: #fff;
    padding: 0 25px;
    margin-top: -12px;
    display: inline-block;
    vertical-align: top;
  }
  &__subheader {
    font-size: 14px;
    text-transform: uppercase;
    @media screen and (min-width: $break-large) {
      font-size: 18px;
    }
  }
  &--table-view {
    text-align: left;
  }
  &__caption {
    text-transform: lowercase;
  }
}
.ts-mod-header-light__total {
  font-size: 28px;
  font-weight: 300;
  color: #070707;
  @media screen and (min-width: $break-large) {
    font-size: 32px;
  }
}
.mod-header-caption {
  font-size: 12px;
  text-transform: lowercase;
  font-style: italic;
}

.ts-block {
  &__content-section {
    padding: 25px 0 13px;
    &--short {
      padding: 10px 0 25px;
      &:last-child {
        padding-bottom: 0;
      }
    }
    &--key {
      padding: 10px 0 10px;
      &:last-child {
        padding-bottom: 0;
      }
    }
    &.border-bottom {
      border-bottom: 1px solid #D1D9E0;
      margin-bottom: 20px;
    }
    &--working-population,
    &--padd-b-0 {
      padding-bottom: 0;
    }
    &--padd-t-0 {
      padding-top: 0;
    }
  }
  &__content-modul {
    padding: 0 0 13px;
    margin-bottom: 20px;
    &.border-bottom {
      border-bottom: 1px solid #D1D9E0;
    }
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.container-row {
  display: flex;
  margin: 0 -14px;
  &__col {
    width: 50%;
    padding: 0 14px;
    .bordered & {
      &:not(:last-child) {
        border-right: 1px solid #D1D9E0;
      }
    }
  }
}

.container-scroll-out {
  margin-right: -14px;
}
.container-scroll-inner {
  padding-right: 14px;
}

.ts-section-header {
  background-color: #3A414C;
  height: 42px;
  color: #fff;
  padding: 2px 6px 0 8px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__col {
    flex-grow: 0;
  }
  &__title {
    text-transform: uppercase;
    &--bold {
      font-weight: 700;
    }
  }
  &--tabs {
    margin: 0 -40px;
    padding-left: 20px;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    &:hover {
      background-color: lighten(#3A414C, 10%);
    }
  }
  &__action-title {
    color: rgb(136, 145, 164);
    font-size: 12px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &__action-text {
    position: relative;
    //white-space: nowrap;
    animation: fontbiggest 5s infinite;
    //animation-delay: 5s;
    //transition: color .5s, font-size .5s;
    //&:hover {
    //  font-size: 14px;
    //  color: #fff;
    //}

  }

  &__caption {
    color: #BDBDBD;
    font-size: 16px;
    position: relative;
    margin-left: 10px;
    padding-left: 10px;
    &:before {
      content: '';
      width: 1px;
      height: 24px;
      background-color: #BDBDBD;
      left: 0;
      top: -6px;
      position: absolute;
    }
  }
}