* {
  margin:0;
  padding:0;
  font-size:100%;
  line-height:1;
  box-sizing: border-box;
  outline: none;
  list-style: none;
}
html {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}

html, body, .outer-wrap {
  height: 100%;
}
body {
  //background-color: #847f77;
  font-family: 'Muli', sans-serif;
  font-size: 16px;
  width: 100%;
  @media screen and (min-width: $break-large) {
    min-width: 1550px;
  }
  background: url("../images/bg-page.jpg") #847f77 no-repeat center top fixed;
  background-size: cover;
  //background-image: url("../images/bg-page.jpg");
  //background-size: cover;
  //background-repeat: no-repeat;
  //background-position: center top;
  //background-attachment: fixed;
}
.clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}
.wrap {
  min-height: 100%;
  height: auto;
  margin: 0 auto -40px;
  padding: 56px 0 40px;
  position: relative;
}
.bg-gtm {
  background: linear-gradient(237.45deg, #FF9A44 0%, #FC6076 100%);
  background-attachment: fixed;
}
.container {
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
  width: 90%;
  @media screen and (min-width: $break-large) {
    width: 1300px;
  }
  &--home {
    width: 100%;
    @media screen and (min-width: $break-large) {
      width: 1300px;
    }
    .is-active & {
      width: 900px;
      @media screen and (min-width: $break-small) {
        width: 1100px;
      }
      @media screen and (min-width: $break-medium) {
        width: 1200px;
      }
      @media screen and (min-width: $break-large) {
        width: 1300px;
      }
    }
  }
  &--metro,
  &--item-segment {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &--gtm {
    margin-top: 15px;
    padding: 0;
  }
  &--blurred {
    padding: 0;
  }
  &--legend {
    padding: 0;
  }
}
.content-shadow {
  box-shadow: 1px 3px 12px 1px rgba(0, 0, 0, 0.2);
}
.container-table-header {
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  .is-sticky & {
    border-radius: 0;
    width: 100%;
  }
  @media screen and (min-width: $break-large) {
    width: 1300px;
  }
}
.main-container {
  background-color: #fff;
  min-height: calc(100vh - 183px);
  margin-top: 40px;
  margin-bottom: 40px;
  &.gtm-content {
    min-height: auto;
  }

  &--gtm-segment {
    margin-top: 0;
  }

  &.is-head-sticky {
    margin-top: 116px;
  }

  box-shadow: 1px 3px 12px 1px rgba(0, 0, 0, 0.2);
  .animation-page & {
    min-height: calc(100vh - 66px);
    padding-bottom: 40px;
  }
  &.container--metro,
  &.container--blurred{
    min-height: calc(100vh - 197px);
  }
  &.container--item-segment {
    min-height: auto;
  }
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  //background: rgba(0, 0, 0, .5);
  background-color: hsla(0,0%,100%,.6);
  -webkit-backdrop-filter: blur(18px);
  backdrop-filter: blur(18px);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 220;
  display: none;
  &.is-visible {
    display: block;
  }
}

.main-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: hsla(0,0%,100%,.6);
  -webkit-backdrop-filter: blur(18px);
  backdrop-filter: blur(18px);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 220;

  transform: scale(1);
  transition: all 0.5s ease 0s;
  overflow: hidden;
  &.is-disabled {
    opacity: 0;
    transform: scale(1.35);
    visibility: hidden;
  }

  &__logo {
    font-size: 270px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 270px;
    height: 327px;
  }
  &__preload-gauge {
    background: #D2232A;
    opacity: 1;
    height: 2px;
    position: absolute;
    width: 0%;
    z-index: 900;
    left: 0;
    top: 0;
    &.is-animate {
      animation: preload 1.5s linear 0s;
    }
  }
}

@keyframes preload {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.page-footer {
  padding-top: 20px;
  &--t-right {
    text-align: right;
  }
}

.button {
  height: 40px;
  line-height: 40px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 50px;
  border: 0;
  padding: 0 46px;
  background-color: green;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  &:hover {
    background-color: darken(green, 10%);
  }
  &.red {
    background-color: #D2232A;
    &:hover {
      background-color: darken(#D2232A, 10%);
    }
  }
  &.blue {
    background-color: #0096db;
    &:hover {
      background-color: lighten(#0096db, 10%);
    }
  }
}

.main-title {
  background-color: #00325b;
  height: 76px;
  width: 100%;

  transition:all ease-out .5s;
  @media screen and (min-width: $break-large) {
    min-width: 1440px;
  }
  &.is-sticky {
    position: fixed;
    width: 100%;
    z-index: 10;
    height: 50px;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 76px;
    padding: 0 5%;
    @media screen and (min-width: $break-large) {
      padding: 0 140px;
    }
    .is-sticky & {
      height: 50px;
    }
    &--c-start {
      justify-content: flex-start;
    }
  }
  &__text {
    font-size: 42px;
    color: #f26522;
    text-transform: uppercase;
    padding-top: 8px;
    .is-sticky & {
      font-size: 36px;
    }
  }
  &__action {
    font-size: 15px;
    color: #fff;
    cursor: pointer;
    margin-left: 20px;
    &:hover {
      text-decoration: none;
      opacity: .8;
    }
    &--lowercase {
      text-transform: lowercase;
    }
  }
  &__action-container {
    flex-grow: 3;
  }
  &__navigation {

  }
}

@keyframes fontbiggest {
  0%, 30%, 70%, 100% {
    color: rgb(136, 145, 164);
  }
  50% {
    color: #fff;
  }
}

.sticker {
  text-align: center;
  &.in-groups {
    padding-bottom: 10px;
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__count {
    font-size: 30px;
    font-weight: 300;
    @media screen and (min-width: $break-large) {
      font-size: 34px;
    }
  }
  &__density-count {
    font-size: 42px;
    &.is-total {
      color: #0095D6;
    }
  }
  &__name {
    font-size: 12px;
    text-transform: uppercase;
    @media screen and (min-width: $break-large) {
      font-size: 16px;
    }
  }
  &__caption {
    color: #8CA0B3;
    font-size: 14px;
    text-transform: lowercase;
    padding-top: 4px;
  }
  &__caption-count {
    color: #4EBBE3;
    padding-top: 4px;
    font-size: 16px;
  }
}

.header-filters {
  display: flex;
  align-items: center;
  margin: 20px -10px 0;
  &__col {
    padding: 0 10px;
  }
  &__col-full {
    flex-grow: 3;
  }
  &__inner {
    display: flex;
    align-items: center;
  }
  &__inner-col {}
}

//radio
.ts-radio {
  &__input {
    position: absolute;
    opacity: 0;
    &:checked + .ts-radio__label .ts-radio__icon {
      background-color: #32C1E9;
      border-color: #32C1E9;
      &:before {
        background-color: #fff;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        content: '';
        display: inline-block;
      }
    }
    &.is-error + .ts-radio__label {
      color: #d0011b;
    }
  }
  &__label {
    display: inline-block;
    vertical-align: middle;
  }
  &__text {
    display: inline-block;
    vertical-align: middle;
  }
  &__icon {
    display: inline-block;
    vertical-align: top;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    background-color: #F8F8F8;
    border: 1px solid #D9D9D9;
    line-height: 12px;
    text-align: center;
    border-radius: 50%;
    .ie9 &,
    .ie8 & {
      line-height: 11px;
    }
  }
}

//columns 16
.i-row {
  display: flex;
  margin: 0 -15px;
  &--wrap {
    flex-wrap: wrap;
  }
}
.i-col {
  padding: 0 15px;
  &.is-bordered-r {
    border-right: 1px solid #EAEAEA;
  }
}
.i-col-full {
  width: 100%;
}
.i-col-1-2 {
  width: 50%;
}
.i-col-1-3 {
  width: 33.33%;
}
.i-col-1-4 {
  width: 25%;
}
.i-col-1-5 {
  width: 20%;
}
.i-col-1-7 {
  width: 14.28%;
}
.i-col-1-8 {
  width: 12.5%;
}
.i-col-2-3 {
  width: 66.7%;
}

.segments {
  margin-bottom: 40px;
  background-color: #fff;
  padding: 40px 40px 0 40px;
  margin-top: 40px;
  &.is-modal {
    position: absolute;
    top: 0;
    right: 40px;
    z-index: 100;
    width: 100%;
    left: 0;
    margin-top: 0;
    box-shadow: 1px 3px 12px 1px rgba(0, 0, 0, 0.1);
    .is-sticky & {
      left: 3px;
    }
  }
  &__name {
    color: #000;
    font-size: 12px;
    text-transform: uppercase;
    border-bottom: 1px solid #000;
    padding-bottom: 8px;
    @media screen and (min-width: $break-small) {
      font-size: 13px;
    }
    @media screen and (min-width: $break-medium) {
      font-size: 15px;
    }
  }
  &__list {
    margin-top: 18px;
    margin-bottom: 35px;
  }
  &__close-button {
    right: 18px;
    top: 16px;
    position: absolute;
    cursor: pointer;
    path, ellipse, rect, use, svg, g, circle {
      fill: #8A8EAB;
    }
    &:hover {
      path, ellipse, rect, use, svg, g, circle {
        fill: #CA212A;
      }
    }
  }
}

.filter-list-wrapper {
  margin-top: 14px;
}
.filter-list {
  text-align: left;
  &__item {
    font-size: 12px;
    padding: 5px 14px 5px 5px;
    line-height: 15px;
    cursor: pointer;
    &:hover,
    &.is-active {
      color: #D2232A;
    }
  }
}

.modal-helper {
  position: relative;
}

.header-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EAEAEA;
  padding-bottom: 14px;
  &__hint {
    font-size: 14px;
    padding-top: 20px;
    text-align: right;
  }
  &__hint-caption {
    font-size: 12px;
  }
}