@function size($font-size) {
  @return ($font-size / 1920px) * 100vw;
}
.working-population {
  display: flex;
  align-items: stretch;
  &__col {
    padding: 10px 30px;
    border-right: 1px solid #D1D9E0;
    &:last-child {
      border-right: 0;
    }
  }
  &__col-count {
  }
  &__col-chart {
    flex-grow: 3;
  }
  &__inner {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    &.is-row {
      flex-direction: row;
    }
  }
}