@import '../../../../../assets/styles/variables.scss';
.spoken-language {
  margin-bottom: 10px;
  &__row {
    margin: 0 -7px;
  }
  &__col {
    float: left;
    padding: 0 7px;
    width: 50%;
  }
  &__item {
    margin-top: 7px;
    height: 60px;
    background-color: #F1F1F1;
    line-height: 60px;
    border-radius: 3px;
    position: relative;
  }
  &__data {
    float: left;
    background-color: #B7B7B7;
    width: 77px;
    height: 60px;
    text-align: center;
    border-radius: 3px 0 0 3px;
    color: #fff;
    font-size: 22px;
    line-height: 62px;
    @media screen and (min-width: $break-large) {
      font-size: 26px;
    }
    &--current {
      background-color: #32C1E9;
    }
  }
  &__name {
    overflow: hidden;
    line-height: 20px;
    padding-left: 14px;
    font-size: 12px;
    @media screen and (min-width: $break-large) {
      font-size: 16px;
    }
  }
  &__name-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-transform: uppercase;
  }
}