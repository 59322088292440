@function size($font-size) {
  @return ($font-size / 1920px) * 100vw;
}
.ts-dropdown {
  position: relative;
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  &--full {
    width: 100%;
  }
  &--on-header {
    color: #4a4a4a;
  }
  &__icon {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
  }
  &__header {
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    background: #fff;
    font-size: 14px;
    padding: 0 25px 0 15px;
    height: 40px;
    cursor: pointer;
    .open & {
      border-radius: 6px 6px 0 0;
    }
    .ts-dropdown--on-header & {
      height: 32px;
    }
  }
  &__menu {
    border-color: #D9D9D9;
    border-width: 0 1px 1px;
    border-style: solid;
    background-color: #fff;
    width: 100%;
    position: absolute;
    z-index: 999;
    border-radius: 0 0 6px 6px;
    display: none;
    .open & {
      display: block;
    }
  }
  &__list {
    margin: 0;
    padding: 10px;
    list-style: none;
    box-sizing: border-box;
  }
  &__list-item {
    padding: 5px 0;
    position: relative;
    font-size: 14px;
    &:hover,
    &.is-checked {
      color: #32C1E9;
      .ts-dropdown__icon {
        path, ellipse, rect, use, svg, g, circle {
          fill: #32C1E9;
        }
      }
    }

  }
  &__data {
    cursor: pointer;
  }
  &__arrow {
    position: absolute;
    right: 10px;
    top: 13px;
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-width: 4px;
      border-style: solid;
      border-color: #000 transparent transparent transparent;
      position: absolute;
      top: 5px;
      right: 4px;
      .open & {
        border-color: transparent transparent #000 transparent;
        top: 1px;
      }
    }
    .ts-dropdown--on-header & {
      top: 4px;
    }
  }
  &__input {
    height: 38px;
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    display: block;
    line-height: 38px;
    min-width: 120px;
    &:focus,
    &:active {
      outline: none;
    }
    .ts-dropdown--on-header & {
      line-height: 32px;
    }
  }
  &__menu-head {
    padding: 10px 0 0 12px;
  }
  &__action-link {
    font-size: 12px;
    color: #32C1E9;
    border-bottom: 1px dashed #32C1E9;
    cursor: pointer;
    &:hover {
      border-bottom: 0;
    }
  }
  &__group-name {
    padding-bottom: 5px;
    cursor: pointer;
    font-weight: 700;
  }
  .ts-checkbox__label {
    cursor: pointer;
  }
  &__radiobutton {
    position: absolute;
    visibility: hidden;
  }
  &__scroll-outer {
    padding: 5px 0;
  }
  &__scroll-body {
    max-height: 260px;
    overflow: hidden;
    .mCSB_inside>.mCSB_container {
      margin-right: 18px;
    }
  }
}