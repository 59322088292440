@import '../../../assets/styles/variables.scss';
.go-table {
  width: 100%;
  font-size: 13px;
  color: #000;
  &.is-bordered {
    border: 1px solid #EBEBEB;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  }
  &.is-history {
    max-width: 586px;
    .go-table__th:first-child {
      width: 115px;
    }
  }
  &__th,
  &__td,
  &__tf {
    text-align: center;
    &:first-child {
      text-align: left;
    }
  }
  &__td,
  &__tf {
    padding: 8px 14px 6px;
  }
  &__th {
    background-color: #3B3B3B;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 12px 14px 10px;
    &.t-small {
      font-size: 14px;
    }
    &--data {
      width: 130px;
    }
    &--traffic {
      width: 100px;
    }
    &--summary {
      width: 88px;
    }
    &--competition {
      width: 98px;
    }
    &--patron-profile {
      width: 140px;
    }
    &--time-detail {
      width: 100px;
      padding: 19px 14px 18px;
    }
    &--day-of-the-week {
      width: 116px;
    }
    &--key-markets {
      width: 170px;
    }
    &--notable-operators {
      width: 140px;
    }
    &--chains-tab {
      width: 180px;
    }
    .is-period-selected &:nth-child(2) {
      background-color: #22A8F3;
    }
    .is-weekend-selected &:nth-last-child(-n+2) {
      background-color: #22A8F3;
    }
  }
  &__td {
    background-color: #fff;
    border-bottom: 1px dashed #D4D4D4;
    font-size: 13px;
    &.is-colored {
      background-color: rgba(86, 204, 242, 0.1);
    }
    &.is-colored-grey {
      background-color: #F0F0F0;
    }
    .is-period-selected &:nth-child(2) {
      background-color: rgba(86, 204, 242, 0.1);
    }
    .is-weekend-selected &:nth-last-child(-n+2) {
      background-color: rgba(86, 204, 242, 0.1);
    }
    &--destination:not(:first-child) {
      width: 78px;
      &:nth-child(3) {
        width: 94px;
      }
    }

    .is-time-detail & {
      padding: 13px 14px 10px;
    }
    &.is-centered {
      text-align: center;
    }
  }
  tr:hover .go-table__td {
    background-color: #D6F3FB;
    color: #000;
  }
  tr:last-child {
    .go-table__td {
      border-bottom: 0;
    }
  }
  &.is-chain-header {
    tr:nth-last-child(-n+2) {
      .go-table__td {
        background-color: #F0F0F0;
      }
    }
  }
  &.is-week-table {
    tr:nth-last-child(-n+2) {
      .go-table__td {
        background-color: rgba(86, 204, 242, 0.1);
      }
    }
  }
  &.is-current-selected {
    tr:first-child {
      .go-table__td {
        background-color: #69C43C;
        color: #fff;
        font-size: 18px;
        .is-period-selected &:nth-child(2) {
          background-color: rgba(86, 204, 242, 0.1);
        }
      }
    }
  }
  &.is-hours-colored {
    tr:nth-child(4),
    tr:nth-child(5),
    tr:nth-child(6),
    tr:nth-child(7),

    tr:nth-child(11),
    tr:nth-child(12),
    tr:nth-child(13),
    tr:nth-child(14),

    tr:nth-child(20),
    tr:nth-child(21),
    tr:nth-child(22),
    tr:nth-child(23),
    tr:nth-child(24){
      .go-table__td {
        background-color: rgba(86, 204, 242, 0.1);
      }
    }
  }
  &.is-current-selected.is-period-selected {
    tr:first-child {
      .go-table__td {
        &:nth-child(2) {
          background-color: #61C24E;
        }
      }
    }
  }
  &.is-current-selected.is-weekend-selected {
    tr:first-child {
      .go-table__td {
        &:nth-last-child(-n+2) {
          background-color: #61C24E;
        }
      }
    }
  }
  &__tf {
    background-color: #787878;
    color: #fff;
    font-size: 16px;
    &:first-child {
      text-align: left;
      .is-days-times & {
        text-align: right;
      }
    }
  }
  .t-gray {
    color: #8C8C8C;
  }
  .t-right {
    text-align: right;
  }
  .t-center {
    text-align: center;
  }
  .t-bold {
    font-weight: 700;
  }
  .t-upper {
    text-transform: uppercase;
  }
  .is-market-structure {
    @media screen and (min-width: $break-medium) {
      min-width: 266px;
    }
  }
  &__th-wrapper {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    &:hover {
      .tooltip {
        display: block;
      }
    }
    &--with-sort {
      padding-left: 18px;
    }
  }

  &__th-title {
    display: inline-block;
    span {
      display: block;
    }
    .is-chain-header &,
    .is-chain-traffic &{
      white-space: nowrap;
    }
  }
  &__subtitle-small {
    text-transform: lowercase;

  }

  &__section-title {
    text-align: center;
    background-color: #787878;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    padding: 12px 0 10px;
    &.is-green {
      background-color: #6FCE1C;
    }
    &.is-blue {
      background-color: #02A7EE;
    }
  }

  &__row-action {
    cursor: pointer;
    &.is-selected {
      .go-table__td {
        background-color: #22A8F3;
        color: #fff;
      }
      &:hover {
        .go-table__td {
          background-color: darken(#22A8F3, 10%);
        }
      }
    }
    .is-actived &:hover {
      .go-table__td {
        background-color: darken(#22A8F3, 10%);
        color: #fff;
      }
    }
  }

  &__input-row {
    display: flex;
  }
  &__input-radio {
    margin-right: 5px;
  }
  &__input-label {
    text-transform: uppercase;
  }
  &__input-mark {
    width: 14px;
    min-width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: grey;
    opacity: 0;
    &.is-active {
      opacity: 1;
    }
    &.market_quickservice,
    &.market_otherlodging,
    &.market_catering {
      background-color: rgb(124, 80, 52);
    }
    &.market_fastcasual,
    &.market_nursinghomes,
    &.market_collegesuniversities,
    &.market_grocerysupermarkets,
    &.market_motels,
    &.market_countryclubsgolfcourses,
    &.market_military {
      background-color: rgb(234, 0, 128);
    }
    &.market_midscaledining,
    &.market_cashcarry,
    &.market_bedbreakfast {
      background-color: rgb(69, 185, 12);
    }
    &.market_casualdining,
    &.market_hospitals,
    &.market_k12,
    &.market_nongroceryretailer,
    &.market_hotels,
    &.market_barsnightclubs,
    &.market_corporatediningbi {
      background-color: rgb(252, 188, 17);
    }
    &.market_finedining,
    &.market_retirementcommunities,
    &.market_bakery,
    &.market_resorts,
    &.market_stadiumsballparks,
    &.market_prisons {
      background-color: rgb(212, 74, 25);
    }
    &.market_onpremisevenue,
    &.market_bowlingcenters {
      background-color: rgb(124, 41, 135);
    }
    &.market_oncampusstore,
    &.market_cstoreonpremise {
      background-color: rgb(13, 126, 74);
    }
    &.market_cstore,
    &.market_fitnesscenters {
      background-color: rgb(25, 70, 212);
    }
    &.market_otherrecreation {
      background-color: rgb(170, 170, 170);
    }
    &.market_casinoandgaming {
      background-color: rgb(215, 111, 0);
    }
    &.market_movietheaters {
      background-color: rgb(252, 17, 81);
    }
    &.market_museumsandartlocations {
      background-color: rgb(17, 199, 252);
    }
  }
}

.table-sort {
  cursor: pointer;
  display: inline-block;
  .ts-icon-font {
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    path, ellipse, rect, use, svg, g, circle {
      fill: #fff;
    }
    .asc & {

    }
    .desc &{

    }
  }
}

.table-horizontal-wrapper {
  overflow-x: auto;
  width: 100%;
}

.copy-notification {
  border: 1px solid darken(#D2232A, .8);
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.3);
  background-color: #D2232A;
  display: block;
  font-size: 14px;
  padding: 12px 15px;
  position: fixed;
  z-index: 900;
  min-width: 295px;
  color: #ffffff;
  //right: 0;
  top: -100px;
  transform: translateY(-30px);
  opacity: 0;
  visibility: hidden;
  text-align: center;
  transition: all 0.4s ease 0s;

  left: 50%;
  transform: translateX(-50%);
  &.is-active {
    opacity: 1;
    visibility: visible;
    top: -41px;
  }
}

.table {
  width: 100%;
  text-align: center;
  .t-left {
    text-align: left;
  }
  &__link {
    color: #32C1E9;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &__segment-link {
    text-decoration: none;
    cursor: pointer;
    color: #4F4F4F;
    &:hover {
      text-decoration: none;
      color: #32C1E9;
    }
  }
  &__main-header {
    font-size: 11px;
    height: 51px;
    padding: 9px 8px;
    text-transform: uppercase;
    .is-for-copy & {
      font-size: 10pt;
      color: #000;
      background-color: transparent;
      font-weight: bold;
      border-top: 1px dashed #BDBDBD;
    }
    .caption {
      font-size: 12px;
    }
    @media screen and (min-width: $break-medium) {
      &.col-overview {
        width: 150px;
        min-width: 150px;
      }
      &.col-business {
        width: 150px;
        min-width: 150px;
      }
      &.col-spend {
        width: 150px;
        min-width: 150px;
      }
      &.col-traffic {
        width: 150px;
        min-width: 150px;
      }
      &.col-dayparts {
        width: 150px;
        min-width: 150px;
      }
      &.col-services {
        width: 150px;
        min-width: 150px;
      }
      &.col-sourcing {
        max-width: 158px;
        min-width: 120px;
        &:last-child {
          min-width: 150px;
        }
      }
      &.col-influencers {
        min-width: 90px;
      }
    }
  }
  &__tab-title {
    font-weight: 700;
    font-size: 26px;
    position: relative;
  }
  &__header {
    background-color: #4F4F4F;
    color: #fff;
    padding: 8px 8px;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 0;
    white-space: nowrap;
    .is-for-copy & {
      font-size: 10pt;
      color: #000;
      font-weight: bold;
      background: transparent;
      border-top: 1px dashed #BDBDBD;
    }
    &.is-total {
      background-color: #79a742;
      .is-for-copy & {
        font-size: 10pt;
        color: #000;
        font-weight: bold;
        background: transparent;
        border-bottom: 1px dashed #BDBDBD;
      }
    }
    .table--landscape & {
      &.small {
        width: 144px;
      }
      &.big {
        width: 280px;
      }
    }
  }
  &__td {
    font-size: 13px;
    border-top: 1px dashed #BDBDBD;
    border-bottom: 0;
    padding: 7px 8px 6px;
    color: #4F4F4F;
    white-space: nowrap;
    .is-for-copy & {
      font-size: 10pt;
      color: #000;
    }
    tr:first-child & {
      color: #000;
      border-top: 0;
    }
    tr:last-child & {

    }
  }
  .is-parent + tr .table__td {
    border-top: 0;
  }
  td,
  th {
    &.is-hidden {
      display: none;
    }
  }
}

.table-filter {
  display: inline-block;
  margin-left: 5px;
  height: 6px;
  &__button {
    font-size: 10px;
    cursor: pointer;
  }
}

.table-search {
  display: none;
  position: absolute;
  background-color: #fff;
  top: 26px;
  left: 0;
  padding: 14px 5px 14px 14px;
  width: 148px;
  max-height: 350px;
  color: #000000;
  -webkit-box-shadow: -3px 3px 12px 1px rgba(0, 0, 0, 0.2);
  box-shadow: -3px 3px 12px 1px rgba(0, 0, 0, 0.2);
  z-index: 2;
  &--long {
    width: 200px;
  }
  .is-active & {
    display: block;
  }
  &__input-wrapper {
    padding-right: 9px;
  }
  &__input {
    width: 100%;
    height: 20px;
    border: 1px solid #8A8EAB;
    padding-left: 3px;
  }
}

.last-update-date {
  color: #4F4F4F;
  font-size: 12px;
  padding-top: 25px;
  opacity: .7;
}