@function size($font-size) {
  @return ($font-size / 1920px) * 100vw;
}
.search-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: #fff;
  z-index: 221;
  box-shadow: 1px 3px 12px 1px rgba(0,0,0,0.10);
  border-radius: 24px;
  display: none;
  &.is-visible {
    display: block;
  }
  &__body {
    padding: 26px;
    position: relative;
    font-size: 16px;
    line-height: 18px;
  }
  &__text {
    font-size: 14px;
    line-height: 18px;
  }
  &__name {
    font-weight: 700;
  }
  &__message {
    display: none;
    &.is-visible {
      display: block;
    }
  }
  &__confirm {
    display: none;
    &.is-visible {
      display: block;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    background-color: #000;
    color: #fff;
    border-radius: 0 0 20px 20px;
    position: relative;
  }
  &__button {
    background-color: #f26522;
    cursor: pointer;
    color: #fff;
    text-transform: lowercase;
    padding: 0 20px;
    border-radius: 18px;
    height: 38px;
    line-height: 38px;
    font-size: 16px;
    &:hover {
      background-color: darken(#f26522, 5%);
    }
  }
  &__logo {
    font-size: 108px;
    position: absolute;
    left: 10px;
    top: -28px;
    &--firefly {
      top: -24px;
    }
  }
  &__body-confirm {
    background-color: #000;
    color: #fff;
    border-radius: 24px;
  }
  &__close {
    position: absolute;
    font-size: 12px;
    right: 14px;
    top: 10px;
    cursor: pointer;
    path, ellipse, rect, use, svg, g, circle {
      fill: #fff;
    }
    z-index: 1;
  }
}