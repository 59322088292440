@function size($font-size) {
  @return ($font-size / 1920px) * 100vw;
}
.ts-pagination {
  list-style: none;
  text-align: right;
  margin-top: 20px;
  li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 3px;
    min-width: 30px;
    height: 30px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;

    background-color: #F6F6F6;
    border: 1px solid #D9D9D9;
    color: #545454;
    text-decoration: none;
    padding-top: 7px;
    &.active,
    &:hover {
      color: #fff;
      background-color: #32C1E9;
      border-color: #32C1E9;
    }

    &:last-child {
      margin-right: 0;
    }
    a,
    span {
      display: block;
      min-width: 30px;
      height: 30px;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
    }
    &.hidden {
      display: none;
    }
  }
  &__link {
    background-color: #F6F6F6;
    border: 1px solid #D9D9D9;
    color: #545454;
    text-decoration: none;
    padding-top: 7px;
    .active &,
    &:hover {
      color: #fff;
      background-color: #32C1E9;
      border-color: #32C1E9;
    }
    .edge & {
      width: auto;
      padding: 7px 10px 0;
      text-transform: uppercase;
    }
  }
  .last {
    text-transform: uppercase;
  }
  .disabled {
    width: auto;
    padding: 7px 10px 0;
    background-color: #F6F6F6;
    border: 1px solid #D9D9D9;
    color: #D9D9D9;
    cursor: default;
    &:hover {
      background-color: #F6F6F6;
      border: 1px solid #D9D9D9;
      color: #D9D9D9;
    }
  }
  .prev,
  .next {
    display: none;
  }
}