.holder-hint {
  position: absolute;
  visibility: hidden;
}
.wrapper-hint {
  display: inline-block;
  &:hover .holder-hint {
    visibility: visible;
  }
}
.table-hint-outer {
  position: relative;
  margin: -14px -10px;
  padding: 14px 10px;
  &:hover {
    .tip-body {
      display: block;
    }
  }
}
.tip-body {
  position: absolute;
  display: none;
  width: 352px;
  text-align: left;
  color: #3A414C;
  background-color: #fff;
  border: 1px solid #A4AAAE;
  border-radius: 3px;
  bottom: 21px;
  margin-left: -5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  cursor: default;
  padding: 11px 15px 15px;
  text-transform: none;
  z-index: 100;
  box-shadow: 1px 3px 12px 1px rgba(0,0,0,0.10);
  &.is-active {
    display: block;
  }
  &__header {
    text-transform: uppercase;
    font-size: 16px;
    border-bottom: 1px solid #000;
    padding-bottom: 5px;
    margin-bottom: 7px;
    font-weight: 700;
  }

  &:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    border: 9px solid;
    border-color: #A4AAAE transparent transparent transparent;
    left: 50%;
    bottom: -18px;
    margin-left: -8px;
  }
  &:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    border: 7px solid;
    border-color: #fff transparent  transparent transparent;
    left: 50%;
    bottom: -14px;
    margin-left: -6px;
  }

  &--left {
    left: 0;
    &:before {
      left: 7%;
    }
    &:after {
      left: 7%;
    }
  }

  &--dark {
    background-color: #3A414C;
    color: #fff;
    border: 1px solid #000;
    padding: 10px 12px 12px;
    width: auto;
    white-space: nowrap;
    &:before {
      border-color: #000 transparent transparent transparent;
    }
    &:after {
      border-color: #3A414C transparent  transparent transparent;
    }
  }
  &--gooey-menu {
    bottom: 5px;
    padding: 7px;
    right: 42px;

    &:before,
    &:after {
      border: 0;
    }
  }
  &--gooey-menu-parent {
    bottom: 5px;
    padding: 7px;
    right: 17px;

    &:before,
    &:after {
      border: 0;
    }
  }
  &--summary-table {
    background-color: #3A414C;
    color: #fff;
    border: 1px solid #000;
    padding: 7px 8px 6px;
    max-width: 220px;
    text-align: center;
    bottom: auto;
    white-space: normal;
    &:before {
      border-color: #000 transparent transparent transparent;
    }
    &:after {
      border-color: #3A414C transparent  transparent transparent;
    }
    &.is-down {
      bottom: auto;
      top: 9px;
      &:before {
        bottom: auto;
        top: -19px;
        border-color: transparent transparent #000 transparent;
      }
      &:after {
        bottom: auto;
        top: -14px;
        border-color: transparent transparent #3A414C transparent;
      }
    }
  }
  &--summary-table-segment {
    background-color: #fff;
    color: #000;
    border: 1px solid #e6e6e6;
    padding: 10px 8px 9px;
    max-width: 274px;
    bottom: auto;
    white-space: normal;
    &:before {
      border-color: #e6e6e6 transparent transparent transparent;
    }
    &:after {
      border-color: #fff transparent  transparent transparent;
    }
    &.is-down {
      bottom: auto;
      top: 9px;
      &:before {
        bottom: auto;
        top: -18px;
        border-color: transparent transparent #e6e6e6 transparent;
      }
      &:after {
        bottom: auto;
        top: -14px;
        border-color: transparent transparent #fff transparent;
      }
    }
    &.is-inner {
      width: 146px;
      bottom: 44px;
      top: auto;
      left: 50%;
      transform: translateX(-50%);
      margin-left: -2px;
      @media screen and (min-width: $break-small) {
        width: 200px;
      }
      @media screen and (min-width: $break-medium) {
        width: 220px;
      }
      @media screen and (min-width: $break-large) {
        width: 274px;
      }
    }
  }
  &--trick {
    padding: 7px 8px 6px;
    max-width: 220px;
    text-align: center;
    bottom: auto;
    visibility: hidden;
    display: block;
    white-space: normal;
    &:before {
      border-color: #000 transparent transparent transparent;
    }
    &:after {
      border-color: #3A414C transparent  transparent transparent;
    }
  }
  &--segment-trick {
    padding: 7px 8px 6px;
    max-width: 274px;
    text-align: center;
    bottom: auto;
    visibility: hidden;
    display: block;
    white-space: normal;
    &:before {
      border-color: #000 transparent transparent transparent;
    }
    &:after {
      border-color: #3A414C transparent  transparent transparent;
    }
  }
  &.is-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 0;
  }
  &.is-social {
    bottom: 43px;
    &.is-down {
      bottom: auto;
      top: 43px;
      &:before {
        bottom: auto;
        top: -18px;
        border-color: transparent transparent #000 transparent;
      }
      &:after {
        bottom: auto;
        top: -14px;
        border-color: transparent transparent #3A414C transparent;
      }
    }
  }
  &.is-indexing {
    bottom: 58px;
  }
  &.is-at-glance {
    bottom: 30px;
    &.is-down {
      bottom: auto;
      top: 9px;
      &:before {
        bottom: auto;
        top: -19px;
        border-color: transparent transparent #000 transparent;
      }
      &:after {
        bottom: auto;
        top: -14px;
        border-color: transparent transparent #3A414C transparent;
      }
    }
    &:before {
      margin-left: -10px;
    }
    &:after {
      margin-left: -8px;
    }
  }
  &.is-offset-l {
    margin-left: -8px;
  }

  &__block-data {
    display: table;
    width: 100%;
    font-size: 1.012em;
  }
  &__row {
    display: table-row;
  }

  &__col-l,
  &__col-r {
    display: table-cell;
    padding: 2px;
  }
  &__col-r {
    text-align: right;
    padding-left: 15px;
  }
}