@import '../../../../../assets/styles/variables.scss';
.total-population {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  &__col {
    flex-grow: 0;
    padding: 0 5px;
    &--left {
      text-align: right;
    }
  }
  &__name {
    font-size: 14px;
    text-transform: uppercase;
    @media screen and (min-width: $break-large) {
      font-size: 18px;
    }
  }
  &__data {
    font-size: 20px;
    @media screen and (min-width: $break-medium) {
      font-size: 24px;
    }
    @media screen and (min-width: $break-large) {
      font-size: 28px;
    }
    &--male {
      color: #1C6B95;
    }
    &--female {
      color: #F4559E;
    }
  }
}

.pie-chart {
  &__pie {
    //width: 142px;
    //height: 147px;
  }
}