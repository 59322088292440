@import '../../../assets/styles/variables.scss';
.main-search {
  width: 275px;
  height: 40px;
  position: relative;
  border: 0;
  background-color: #F7F7F7;
  border-radius: 20px;
  padding-left: 26px;
  @media screen and (min-width: $break-medium) {
    width: 305px;
  }
  .compare-chains & {
    width: 305px;
    height: 26px;
    border-color: #636363;
  }
  &__icon {
    position: absolute;
    right: 16px;
    top: 10px;
    font-size: 22px;
    &--right {
      left: 0;
      right: auto;
    }
  }
  &__field {
    height: 40px;
    line-height: 40px;
    width: 206px;
    border: 0;
    background-color: transparent;
    color: #4F4F4F;
    font-size: 16px;
    @media screen and (min-width: $break-medium) {
      width: 236px;
    }
    .compare-chains & {
      color: #5E5E5E;
      font-weight: 300;
      font-size: 14px;
      height: 24px;
      line-height: 24px;
      width: 268px;
    }
  }
  &__list-holder {
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    box-shadow: 0 5px 14px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 13px 3px 15px 15px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 40px;
    z-index: 103;
    display: none;
    &.is-open {
      display: block;
    }
  }
  &__list {
    color: #000;
    padding-right: 15px;
  }
  &__item {
    font-size: 16px;
    line-height: 22px;
    padding: 4px 0 3px 5px;
    &.is-hidden {
      display: none;
    }
    .is-operators &,
    .is-chains & {
      text-transform: uppercase;
    }
    .is-operators & {
      font-size: 12px;
    }
  }
  &__name {
    cursor: pointer;
    color: #000;
    text-decoration: none;
    line-height: 16px;
    display: inline-block;
    &:hover {
      color: #D2232A;
      text-decoration: none;
    }
    .is-disabled & {
      cursor: default;
      color: #BDBDBD;
      &:hover {
        color: #BDBDBD;
      }
    }
  }
  &__city-state {
    font-size: 12px;
    text-transform: none;
  }
  &__group-title {
    background-color: #E5E5E5;
    padding: 7px 5px 4px;
    border-bottom: 1px solid darken(#E5E5E5, 10%);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__group-name {
    color: #D2232A;
  }
  &__group-hint {
    color: #4F4F4F;
    font-size: 12px;
  }
  &__list-footer {
    border-top: 1px solid #EBEBEB;
    padding: 14px 0 0 24px;
    margin: 14px -10px 0 -24px;
    color: #000;
    text-transform: lowercase;
    font-size: 14px;
  }
  &__footer-link {
    cursor: pointer;
    &:hover {
      color: #4AD3FF;
    }
  }
}

.search-simple {
  position: relative;
  float: left;
  margin-top: 7px;
  font-size: 14px;
  &--long {
    width: 426px;
  }
  &__input {
    padding: 0 38px;
    width: 100%;
    height: 40px;
    font-size: 14px;
    border: 0;
  }
  &__icon {
    font-size: 24px;
    line-height: 40px;
    position: absolute;
    color: #888;
    margin-left: 10px;
    left: 0;
  }
}


.all-brands {
  position: absolute;
  background-color: #fff;
  width: 100%;
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 14px;
  left: 0;
  color: #070707;
  z-index: 222;
  display: none;
  max-height: calc(100vh - 70px );

  &.is-open {
    display: block;
  }
  &__header {
    border-bottom: 1px solid #E0E0E0;
    padding: 0 0 10px 0;
    margin-bottom: 10px;
    display: flex;
  }
  &__title {
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 700;
    flex: 1 0 0;
    margin: auto;
    text-align: left;
  }
  &__area-wrapp {
    margin-top: 8px;
  }
  &__area-set {
  }
}

.all-brands-holder {
  position: absolute;
  width: 100%;
  left: 0;
}

.brand-list {
  &__scroll {
    height: 700px;
  }
  &__headers {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  &__header-item {
    flex-grow: 0;
    flex-basis: 25%;
    padding: 0 10px;
  }
  &__header-item-body {
    color: #070707;
    font-size: 19px;
    border-bottom: 1px solid #070707;
    text-transform: capitalize;
  }

  &__items {
    padding-top: 10px;
  }
  &__cols-wrap {
    overflow: hidden;
  }
  &__cols {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  &__col {
    flex-grow: 0;
    flex-basis: 20%;
    padding: 0 10px;
  }
  &__link {
    display: inline-block;
    color: #636363;
    font-size: 13px;
    height: 22px;
    line-height: 24px;
    text-decoration: none;
    text-transform: capitalize;
    border-radius: 3px;
    padding: 0 7px;
    &:hover {
      color: #fff;
      background-color: #4AD3FF;
    }
  }
  &__item {

  }
}