@function size($font-size) {
  @return ($font-size / 1920px) * 100vw;
}
.map-info {
  background-color: #fff;
  padding: 10px 20px;
  border-left: 5px solid #EA607E;
  border-radius: 6px;
  box-shadow: 1px 3px 12px 1px rgba(0,0,0,0.1);
  min-width: 296px;
  font-family: 'Muli', sans-serif;
  &__title {
    color: #4A4A4A;
    font-size: 14px;
    line-height: 18px;
  }
  &__footer {
    border-top: 1px solid #C2C2C2;
    margin-top: 12px;
    padding-top: 12px;
  }
  &__description,
  &__address,
  &__phone {
    line-height: 18px;
  }
  &__name {
    color: #EA607E;
    text-transform: uppercase;
    font-weight: 700;
    float: left;
    padding-right: 10px;
    font-size: 12px;
  }
  &__label {
    font-weight: 700;
  }
  &__parent-segment {
    float: right;
    padding-left: 10px;
    font-weight: 700;
  }
  &__dot {
    width: 11px;
    height: 11px;
    display: inline-block;
    background-color: #767676;
    margin-right: 5px;
    border-radius: 50%;
  }
}

.gm-style .gm-style-iw {
  overflow: visible;
  & > div {
    overflow: visible !important;
    & > div {
      overflow: visible !important;
    }
  }
}

.gm-style .gm-style-iw-c {
  padding: 0;
}

.gm-style-iw {
  min-width: 296px;
}

.gm-style {
  font-family: 'Muli', sans-serif;
}

.cross {
  //content: 'xD';
  img {
    display: none !important;
  }
}