.tabs {
  display: flex;
  align-items: flex-end;
  margin: 0 -11px;
  &__items-row {
    margin: 0 11px;
    padding: 0 11px;
    position: relative;
  }
  &__items-row-inner {
    display: flex;
    align-items: flex-end;
    margin: 0 -11px;
  }
  &__item {
    position: relative;
    color: #fff;
    font-weight: 300;
    font-size: 18px;
    cursor: pointer;
    text-transform: uppercase;
    margin: 0 11px;
    transition: all 1.2s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: #f26522;
    }
    &.is-active {
      color: #f26522;
      font-weight: 700;
      font-size: 28px;
      line-height: 24px;
      cursor: default;
    }
    &.is-disabled {
      opacity: .5;
      &:hover {
        color: #fff;
      }
    }
  }
  &__item-hint {
    color: #0096db;
    font-size: 12px;
    text-transform: none;
    position: absolute;
    margin-top: 4px;
    width: 100%;
    text-align: center;
    left: 0;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      border-top: 1px solid #0096db;
      margin-top: 6px;
      z-index: 1;
      left: 0;
    }
  }
  &__item-hint-text {
    padding: 0 6px;
    background-color: #00325b;
    display: inline-block;
    position: relative;
    z-index: 2;
  }
}

.section-summary-tabs {
  display: flex;
  align-items: flex-end;
  align-content: center;
  border-bottom: 1px solid #EAEAEA;
  padding-bottom: 14px;
  &--segments {
    margin-top: 10px;
    height: 40px;
    padding-bottom: 0;
    border-bottom: 0;
  }
  &__item {
    position: relative;
    color: #C4C4C4;
    font-weight: 300;
    font-size: 18px;
    cursor: pointer;
    text-transform: uppercase;
    margin: 0 11px;
    transition: all 1.2s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    @media screen and (min-width: $break-small) {
      font-size: 22px;
    }
    @media screen and (min-width: $break-medium) {
      font-size: 25px;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: #D4C094;
    }
    &.is-active {
      color: #D4C094;
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
      cursor: default;
      @media screen and (min-width: $break-small) {
        font-size: 26px;
        line-height: 24px;
      }
      @media screen and (min-width: $break-medium) {
        font-size: 35px;
      }
    }
  }
}

.stack-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
  @media screen and (min-width: $break-large) {
    width: 1300px;
  }
  &__item {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 300;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    padding: 70px 0;
    text-align: center;
    @media screen and (min-width: $break-small) {
      font-size: 20px;
    }
    &:hover {
      color: #fff;
    }
    &.is-active {
      color: #fff;
      font-size: 20px;
      font-weight: 400;
      @media screen and (min-width: $break-small) {
        font-size: 28px;
      }
    }
  }
}