@function size($font-size) {
  @return ($font-size / 1920px) * 100vw;
}
.main-nav {
  $root: &;
  left: -25px;
  min-height: 590px;
  display: flex;
  flex-direction: column;
  &__item {
    width: 272px;
    color: #fff;
    height: 56px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 56px;
    display: flex;
    text-decoration: none;
    position: relative;
    sup {
      font-size: 8px;
      margin: 21px 0 0 2px;
      vertical-align: super;
      font-weight: 400;
    }
    &.is-locked {
      color: #7D8387;
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 12px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transform: translate(0, -50%);
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__icon-lock {
    position: absolute;
    top: 19px;
    right: 24px;
    //width: 16px;
    //height: 16px;
  }
  &__parent {
    padding-left: 32px;
    text-transform: uppercase;
    background-color: #414447;
    position: relative;
    border-bottom: 1px solid lighten(#414447, 5%);
    &:hover {
      background-color: lighten(#5E6266, 5%);
      border-bottom: 1px solid lighten(#5E6266, 5%);
    }
    &.is-gtm.is-active,
    &.is-food-journey.is-active {
      background-color: #5E6266;
    }
  }
  &__child {
    padding-left: 48px;
    position: relative;
    background-color: #414447;
    &.is-active {
      background-color: #5E6266;
    }
    &.is-landscape {
      border-bottom: 1px solid lighten(#414447, 5%);
      &:hover {
        background-color: lighten(#5E6266, 5%);
        border-bottom: 1px solid lighten(#5E6266, 5%);
      }
    }
    &:last-child {
      border-bottom-color: #414447;
      &:hover {
        border-bottom-color: #5E6266;
      }
    }
  }
  &__menu-parent-mark {
    position: absolute;
    width: 12px;
    height: 12px;
    background: #79a742;
    border-radius: 50%;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    &.is-landscape {
      background-color: #79a742;
    }
    &.is-gtm {
      background-color: #933F6B;
    }
    &.is-food-journey {
      background-color: #0C6484;
    }
  }
  &__menu-item-mark {
    position: absolute;
    width: 8px;
    height: 8px;
    background: #79a742;
    border-radius: 50%;
    top: 50%;
    left: 22px;
    transform: translateY(-50%);
  }
  &__footer {
    background-color: #414447;
    text-align: center;
    flex-grow: 3;
    min-height: 197px;
  }
  &__footer-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 30px 20px;
    position: absolute;
    justify-content: space-between;
  }
  &__footer-logo-ifma {
    width: 98px;
    height: auto;
  }
  &__footer-link {
    border: 1px solid #79a742;
    border-radius: 8px;
    height: 40px;
    padding: 0 16px;
    line-height: 37px;
    color: #fff;
    font-size: 18px;
    display: inline-block;
    text-decoration: none;
    transition: border-color .3s,background-color .3s, color .3s;
    &:hover {
      text-decoration: none;
      border-color: #79a742;
      background-color: #79a742;
      color: #fff;
      #{$root}__footer-link-icon-holder {
        path, ellipse, rect, use, svg, g, circle {
          fill: #fff;
        }
      }
      #{$root}__footer-link-text {
        color: #fff;
      }
    }
  }
  &__footer-link-icon-holder {
    padding-top: 3px;
    display: inline-block;
    vertical-align: middle;
    path, ellipse, rect, use, svg, g, circle {
      fill: #79a742;
    }
  }
  &__footer-link-icon {
    width: 16px;
    //height: auto;
    margin-right: 5px;
  }
  &__footer-link-text {
    display: inline-block;
    vertical-align: middle;
    color: #79a742;
  }
  &__footer-logos-inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__footer-logos-item a {
    line-height: 0;
    font-size: 0;
    display: block;
  }
  &__copy-logo {
    margin: 0 10px;
    transition: opacity .3s,transform .3s,-webkit-transform .3s;
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1);
    transform: scale(1);
    &:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
  &__ds-logo {
    width: 120px;
    height: auto;
    margin-top: 4px;
  }
  &__kinetic-logo {
    width: 40px;
    height: 20px;
  }
}

.nav-holder {
  height: 100%;
  z-index: 101;
}

$max-elements-count: 10; /* please use carefully!! every additional element involves additional CSS-code */
$transition-delay-step: 50;
$transition-duration: 200;

.jalousie {
  position: relative;
  $root: &;

  &:hover,
  &#{$root}--opened {
    #{$root}__list {
      opacity: 1;
      pointer-events: auto;
    }

    #{$root}__item {
      opacity: 1 !important;
      transform: rotateX(0deg);

      @for $i from 1 to $max-elements-count {
        &:nth-child(#{$i}) {
          transition-delay: #{$i*$transition-delay-step}ms;
        }
      }
    }
  }

  &__list {
    position: absolute;
    top: 100%;
    pointer-events: none;
    //perspective: 500px;
  }

  &__item {
    opacity: 0;
    position: relative;

    transform: rotateX(-105deg);
    transform-origin: 50% 0;
    animation-iteration-count: 1, 1;

    transition-property: transform, opacity;
    transition-duration: #{$transition-duration}ms;
    transition-timing-function: linear;
    backface-visibility: hidden;

    @for $i from 1 to $max-elements-count {
      &:nth-last-child(#{$i}) {
        transition-delay: #{$i*$transition-delay-step}ms;
      }
    }
  }
}