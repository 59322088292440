@function size($font-size) {
  @return ($font-size / 1920px) * 100vw;
}
.login-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 432px;
  background-color: #fff;
  z-index: 221;
  border: 1px solid #CCCCCC;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.09);
  display: none;
  &.is-nda {
    width: 1100px;
  }
  &.is-visible {
    display: block;
  }
  &__body {
    padding: 32px;
    position: relative;
    font-size: 16px;
    line-height: 18px;
  }
  &__text {
    font-size: 14px;
    line-height: 18px;
  }
  &__name {
    font-weight: 700;
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 10px;
    color: #414447;
  }
  &__header {
    text-align: center;
    font-size: 18px;
    position: relative;
    padding-bottom: 50px;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 56px;
    position: relative;
    height: 48px;
  }
  &__button {
    width: 100%;
    background-color: #D2232A;
    cursor: pointer;
    text-align: center;
    color: #fff;
    padding: 0 20px;
    border-radius: 28px;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    &:hover {
      background-color: darken(#D2232A, 5%);
    }
    &.is-nda {
      width: 320px;
      text-transform: uppercase;
      font-weight: bold;
      margin-right: 50px;
    }
  }
  &__button-disagree {
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: #414447;
    margin-left: 50px;
  }
  &__logo {
    font-size: 108px;
    position: absolute;
    left: 10px;
    top: -28px;
    &--firefly {
      top: -24px;
    }
  }
  &__body-confirm {
    background-color: #000;
    color: #fff;
    border-radius: 24px;
  }
  &__close {
    position: absolute;
    font-size: 12px;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 1;
  }
  &__confirmation {
    line-height: 18px;
  }
  &__form {
    margin: 10px auto;
  }
  &__field-holder {
    position: relative;
    padding-bottom: 50px;
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__field {
    width: 100%;
    height: 34px;
    border-color: #B8C6D8;
    border-width: 0 0 1px 0;
    border-style: solid;
    font-size: 16px;
    &.is-error {
      border-color: #D2232A;
    }
  }
  &__field-label {
    color: #7D8387;
    font-size: 14px;
    position: absolute;
    top: -14px;
    opacity: 0;
    &.is-visible {
      animation: label 1s ease-out forwards 1;
    }
  }
  &__footer {
    padding-top: 24px;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    color: #0C6484;
  }
  &__link {
    color: #32C1E9;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &__error-text {
    color: #D2232A;
    font-size: 12px;
    position: absolute;
    bottom: -10px;
    width: 100%;
  }
  &__icon {
    font-size: 31px;
  }
  &__logo-holder {
    text-align: center;
    padding-bottom: 20px;
  }
  //svg,
  &__logo-ifma {
    width: 304px;
    height: 65px;
  }
}
@keyframes label {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.nda-content {
  color: #414447;
  &__text {
    font-size: 16px;
    padding: 8px 0;
  }
  &__title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
    padding: 20px 0 8px;
  }
}

.email-success {
  text-align: center;
  &__icon-holder {
    width: 100px;
    height: 100px;
    background-color: #0C6484;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto 16px;
    font-size: 48px;
    padding-top: 16px;
  }
  &__text {
    color: #414447;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
  }
}