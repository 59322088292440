.gtm-content {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
  &__col {
    width: 50%;
  }
  &__col-info {
    width: 40%;
    padding-right: 1.5%;
    @media screen and (min-width: $break-medium) {
      width: 50%;
    }
  }
  &__col-buttons {
    width: 60%;
    @media screen and (min-width: $break-medium) {
      width: 50%;
    }
  }
}

.gtm-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  padding-bottom: 18px;
  @media screen and (min-width: $break-medium) {
    font-size: 36px;
    line-height: 50px;
    padding-bottom: 22px;
    max-width: 450px;
  }
}

.button-choose {
  background-color: #333333;
  border-radius: 28px;
  color: #fff;
  font-size: 20px;
  width: 140px;
  height: 50px;
  cursor: pointer;
  border: 0;
  @media screen and (min-width: $break-medium) {
    font-size: 24px;
    width: 154px;
    height: 60px;
  }
  &:hover {
    opacity: .9;
  }
  &__text {
    display: inline-block;
    vertical-align: middle;
  }
  &__icon {
    display: inline-block;
    vertical-align: middle;
    line-height: 0;
    padding-left: 10px;
    svg {
      width: 40px;
      height: 10px !important;
    }
    .is-back & {
      height: 12px;
      padding-right: 10px;
      padding-left: 0;
      transform: rotate(180deg);
    }
  }
  &:disabled {
    background-color: #CBCFDF;
    cursor: default;
    &:hover {
      opacity: 1;
    }
  }
  &.is-back {
  }
}

.preview {
  text-align: center;
  margin: 0 auto;
  &__header {
    font-size: 24px;
    padding-bottom: 16px;
    font-weight: 700;
    @media screen and (min-width: $break-medium) {
      font-size: 28px;
    }
  }
  &__title {
    font-size: 24px;
    line-height: 36px;
    max-width: 1000px;
    padding-bottom: 30px;
    @media screen and (min-width: $break-medium) {
      font-size: 28px;
      line-height: 40px;
    }
  }
}

.gtm-footer {
  display: flex;
  padding: 0 0 40px;
  width: 90%;
  margin: 0 auto;
  @media screen and (min-width: $break-large) {
    width: 1300px;
  }
  &.is-right {
    justify-content: flex-end;
  }
  &.is-justify {
    justify-content: space-between;
  }
}

.legend-holder {
  transition: all 0.5s ease;
  background-color: #E5E5E5;
  padding: 15px 0;
  &.is-sticky {
    position: fixed;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    width: 100%;
    top: 56px;
    left: 0;
    z-index: 10;
    transition: all 0.5s ease 0s;
  }
}
.segment-legend {
  display: flex;
  justify-content: flex-end;
  &__item {
    padding-left: 32px;
    color: #4F4F4F;
    font-size: 16px;
  }
  &__item-inner {
    display: flex;
    align-items: center;
  }
  &__text {
    padding-left: 5px;
    flex-grow: 0;
    font-size: 14px;
    @media screen and (min-width: $break-medium) {
      font-size: 16px;
    }
  }
  &__dot-set {
    flex-grow: 0;
  }
  &__dot {
    display: inline-block;
    background-color: #933F6B;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 2px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}