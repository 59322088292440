@function size($font-size) {
  @return ($font-size / 1920px) * 100vw;
}
.animation-nav {
  background-color: #1C222A;
  position: absolute;
  top: 106px;
  z-index: 101;
  width: 100%;
  height: 255px;
  pointer-events: none;
  opacity: 0;
  transition: transform 1.2s, opacity 1.2s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transform: translate3d(0, 150px, 0);
  &.is-active {
    pointer-events: auto;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animation-page {
  z-index: 100;
  pointer-events: none;
  perspective: 1200px;
  perspective-origin: 50% -50%;
  position: relative;
  overflow: hidden;
  .animation-page__inner {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    @media screen and (min-width: $break-large) {
      width: 1300px;
    }
      .note {
        color: #fff;
        padding-top: 13px;
        margin-bottom: -26px;
        font-size: 13px;
        pointer-events: auto;
        .note-link {
          text-decoration: none;
          font-weight: bold;
          color: #fff;
          cursor: pointer;
          border-bottom: 1px #fff dashed;
          &:hover {
            text-decoration: none;
            opacity: .8;
            border-bottom-color: transparent;
          }
        }
        .assumption-pdf-link {
          float: right;
        }
      }
  }
  .page {
    position: absolute;
    top: 0;
    z-index: 5;
    pointer-events: auto;
    transform-origin: center bottom;
    opacity: 0;
    &.is-current {
      opacity: 1;
      position: relative;
    }
    transition: transform 0.45s, opacity 0.45s;
    transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
    @for $i from 1 through 8 {
      $zIndex: (20 - $i);

      &-#{$i} {
        z-index: $zIndex;
      }
    }
    &-1 {
      transform: translate3d(0px, 0px, 0px);
    }
    @for $i from 2 through 8 {
      $zIndex: (20 - $i);

      &-#{$i} {
        transform: translate3d(0px, 100%, 0);
      }
    }
  }
  &.is-active {
    height: calc(100vh - 128px);

    .page {
      position: absolute;
      z-index: 0;
      top: 0;
      height: 1368px;
      .main-container--home {
        margin-top: 0;
      }
      &.is-visible {
        opacity: 1;
      }

      cursor: pointer;

      @for $i from 1 through 3 {
        $translateX: (-80 + 40 * $i);
        $translateY: (-60 - 40 * $i);
        $zIndex: (20 - $i);
        $blur: 0 + $i - 1;
        $test: (-150 - 50 * $i);
        $opacity: 1.2 - $i / 10 * 3;

        &-#{$i} {
          transform: translate3d(0px, 40%, #{$test}px);
          z-index: $zIndex;
          .page__inner {
            opacity: $opacity;
          }
        }
      }
      @for $i from 4 through 8 {
        $translateX: (-80 + 40 * 3);
        $translateY: (-60 - 40 * 3);
        $zIndex: (20 - $i);
        $blur: 0 + $i - 1;
        $test: (0,$i);
        $opacity: 1.2 - $i / 10 * 3;

        &-#{$i} {
          transform: translate3d(0px, 40%, -350px);
          z-index: $zIndex;

          .page__inner {
            opacity: $opacity;
          }
        }
      }
      //
    }
  }
}