@function size($font-size) {
  @return ($font-size / 1920px) * 100vw;
}
.chart-operator-spend {
  text {
    font-size: 14px;
  }
}
.recharts-wrapper {
  overflow: hidden;
}
.recharts-label-list {
  text {
    font-weight: 900;
  }
}