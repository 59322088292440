@import '../../../../assets/styles/variables.scss';
.summary-analysis {
  font-size: 1px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &__item {
    text-transform: uppercase;
    text-align: center;
    color: #3A414C;
    font-size: 16px;
    @media screen and (min-width: $break-medium) {
      font-size: 18px;
    }
  }
  &__data {
    font-size: 34px;
    font-weight: 300;
    padding-bottom: 5px;
    @media screen and (min-width: $break-medium) {
      font-size: 40px;
    }
    &--blue {
      color: #0095D6;
    }
    &--green {
      color: #3FA648;
    }
    &--dark-green {
      color: #017720;
    }
    &--red {
      color: #EF453A;
    }
  }
  &__item-helper {
    width: 100%;
    height: 0;
    visibility: hidden;
    overflow: hidden;
    display: inline-block;
  }
}

.summary-chart {
  &__item {
    width: 16.66%;
    float: left;
    text-align: center;
    padding: 0 15px;
    position: relative;
    z-index: 10;
    border-right: 1px solid #979797;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }
  &__title {
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    height: 50px;
    @media screen and (min-width: $break-medium) {
      font-size: 18px;
    }
  }
  &__progress-block {
    width: 4px;
    height: 150px;
    background-color: #F2F2F2;
    margin: 0 auto;
    position: relative;
  }
  &__progress {
    width: 4px;
    height: 0;
    max-height: 94%;
    background-color: #32C1E9;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all .5s ease;
  }
  &__label {
    background-color: #3A414C;
    color: #fff;
    text-align: center;
    height: 22px;
    line-height: 22px;
    text-transform: lowercase;
    padding: 0 15px;
    bottom: 0;
    border-radius: 6px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: all .5s ease;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    @media screen and (min-width: $break-medium) {
      font-size: 16px;
    }
  }
  &__label-inner {
    position: relative;
  }
  &__data {
    font-size: 26px;
    font-weight: 300;
    padding-top: 10px;
    @media screen and (min-width: $break-medium) {
      font-size: 32px;
    }
  }
  &__caption {
    font-size: 11px;
    height: 28px;
    @media screen and (min-width: $break-medium) {
      font-size: 14px;
    }
  }
}