@import '../../../../../assets/styles/variables.scss';
.total-born-us {
  position: relative;
  display: flex;
  justify-content: center;
  width: 90%;
  &__col {
    flex-grow: 0;
    text-align: center;
    padding: 0 10px;
  }
  &__col-chart {
    flex-grow: 3;
  }
}

.chart-born-us {
  width: 298px;
  &__progress-block {
    width: 100%;
    height: 24px;
    color: #fff;
    background-color: #FFD017;
    border-radius: 12px;
    position: relative;
  }
  &__progress {
    position: absolute;
    height: 24px;
    background-color: #32C1E9;
    top: 0;
    left: 0;
    border-radius: 12px;
  }
  &__val {
    position: absolute;
    line-height: 24px;
    text-align: center;
    z-index: 1;
    font-size: 14px;
    @media screen and (min-width: $break-large) {
      font-size: 16px;
      line-height: 26px;
    }
    &--left {
      left: 0;
    }
    &--right {
      right: 0;
    }
  }
}